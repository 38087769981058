.container {
    width: 100vw;
}

.name {
    margin: 15px;
}

.image {
    width: 50%
}

.bio {
  padding-left: 5vw;
  padding-right: 5vw;
  margin-top: 15px;
  margin-bottom: 35px;
}

.sustainability {
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 35px;
}

.all-products-link {

}

.carousel {

}

.carousel-img {
    width: 300px;
}

@media screen and (max-width: 800px) {
    .carousel-img {
        width: 150px;
    }
}

@media screen and (max-width: 550px) {
    .carousel-img {
        width: 100px;
    }
}