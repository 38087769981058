.container {
  box-sizing: border-box;
  font-size: 14px;
  color: black
}

.category-container {
  display: "flex";
  flex-direction: column;
  margin: 8px 0px 8px 0px;
  box-sizing: border-box;
  border-radius: 3px;
  
}

.name {
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  padding: 5px;
  padding-left: 0px;
}

.name:hover {
  color: rgb(39, 39, 39)
}

.hr {
  width: 80%;
  margin: 0px;
  margin-left: 5px;
  border-style: solid;
  border-color: rgb(228 228 228);
  /*
  animation-name: hr;
  animation-duration: 0.5s;
  animation-iteration-count: 1
  */
}

/*
@keyframes hr {
  from {width: 0%}
  to {width: 80%}
}
*/

.arrow-container {
  /*
  padding: 8px;
  margin-left: 5px;
  cursor: pointer;
  */
  cursor: pointer;
  margin-left: 15px;
  padding-top: 3px;
}

.arrow {
  /*
  border: solid #9a9a9a;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  pointer-events: none;
  transform: rotate(45deg);
  transition: transform ease 0.5s
  */
  pointer-events: none;
  width: 15px;
  transition: all ease 0.2s;
} 

.arrow-container:hover .arrow {
  /*
  border: solid rgb(180, 180, 180);
  border-radius: 1px;
  border-width: 0 3px 3px 0;
  */
  pointer-events: none;
  transform: scale(1.1);
  transition: transform ease 0.2s
}