.nav {
  background-color: rgb(248, 248, 248);
  padding: 8px;
  display: flex;
  justify-content: center;
  gap: 5vw;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  height: 16px;
  /*
  overflow-y: hidden;
  overflow-x: scroll;
  */
  

}

/*
.nav::-webkit-scrollbar {
  display: none
}
*/

.dropdown {
  height: 100%;
}

.men-women {
  color: black;
  
}

.link-underline {
  text-decoration: none;
  margin-left: 5px;
  position: relative;
  color: rgb(99, 99, 81);
  color: black;
  
}

.link-underline::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #77ea77;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link-underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.arrow {
  color: #9a9a9a;
  width: 0.5em;
  height: 0.5em;
  display: inline-block;
  vertical-align: middle;
  border-left: 0.15em solid currentColor;
  border-bottom: 0.15em solid currentColor;
  transform: rotate(-45deg);
  margin-left: 0.38em;
  margin-top: -0.25em;
}

@media screen and (max-width: 450px) {
  .blog {
    display: none;
  }

  .nav {
    font-size: .8em;
    justify-content: space-around;
    gap: 2vw;
    font-size: 15px;
  }
}
