.form {
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.form-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
}

.input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 15px;
}