.container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.input {
    font-family: inherit;
    border: solid 1px black;
    border-radius: 3px;
    box-shadow: inset 0 0 8px  rgba(0,0,0,0.1);
    margin-left: 15px;
    height: 19px;
    width: 80%;
}

.input:focus {
    outline: #5eda5e solid 1px;
}

.icon-container {
    display: flex;
    cursor: pointer;
    border: none;
    background: none;
}

.icon {
    width: 20px;
    height: 20px;
}

.icon:hover {
    filter: drop-shadow(1px 1px #5eda5e);
}
