.container {
    
}

.open-container {
    position: absolute;
    top: 35px;
    left: 0px;
    width: 205px;
    height: 73%;
    background-color: white;
}