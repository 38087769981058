.container {
    min-height: 100vh;
    margin-top: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    
}

.landscape-container {
    width: 80%;
    margin-top: 35px;
    position: relative; /* necessary for absolute positioned image */
    padding-bottom: 53%; /* correct for the 3 to 2 aspect ratio for some reason */
    border: 1px solid rgb(133, 133, 133);
    color: white;
    font-size: 5em;
    font-family: 'Raleway', sans-serif;
    z-index: 1;
}

.landscape-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.portraits {
    width: 80%;
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
}

.portrait-container {
    width: 49%;
    position: relative; /* necessary for absolute positioned image */
    padding-bottom: 73.5%; /* image should have the aspect ratio of 2:3 (2 / 3 = .66) (for some reason .735 is the right value for percentage) keeps content reflow from jumping page around https://www.codecaptain.io/blog/web-development/responsive-images-and-preventing-page-reflow/474 */
    border: 1px solid rgb(133, 133, 133);
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 3em;
}

.portrait-image {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
}

.dividers {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 390px;
    background-color: #aeec98;
    clip-path: polygon(0 20%, 100% 0,100% 100%,0 80%)
}

.side-leaves {
    position: fixed;
    z-index: 0;
    bottom: 0px;
    width: 50%;
    filter: blur(8px);
    opacity: 39%
}