.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0px;
    margin: 25px;
    margin-top: 50px
    
}

.container::after {
    content: '';
    flex: auto;
  }

.info-category {
    display: flex;
    justify-content: flex-end;
    
    margin-right: 15px;
    position: absolute;
    right: 0;
    top: 115px
}

.item {
    padding: 0px;
    flex-basis: 25%;
    cursor: pointer;
    margin-bottom: 8px;
}

@media screen and (max-width: 800px) {
    .item {
        flex-basis: 30%
    }
}

@media screen and (max-width: 584px) {
    .item {
        flex-basis: 46%;
    }

    .category-label {
        display: none;
    }
}