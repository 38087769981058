.container {
    position: relative;
    z-index: 993;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 95px;
    margin-top: 15px;
    background-image: url('../image/background-edited-solid.webp');
    background-position-y: 78%;
    background-position-x: 100%;
    
    max-width: 100vw;
}

.links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    /*
    background-color: rgba(65, 51, 39, 0.726);
    background: rgb(66,51,39);
    background: linear-gradient(0deg, rgba(66,51,39,1) 14%, rgba(66,51,39,0.8491771708683473) 59%, rgba(66,51,39,0.8239670868347339) 65%, rgba(66,51,39,0.7343312324929971) 75%, rgba(66,51,39,0.4990371148459384) 86%, rgba(255,255,255,0) 100%);
    */
    background-color: rgba(233, 207, 174, 0.596);
    background-color: #bbbdba91;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.575);
    padding: 12px;
    /* padding-top: 39px; */
    padding-right: 50px;
    padding-left: 50px;
    border-radius: 25px;
    margin-bottom: 30px;
}

.links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: rgba(158, 139, 115, 0.24);
    padding: 15px;
}

.link {
    text-decoration: none;
    color: rgb(255, 255, 255);
    color: rgb(49, 49, 49);
}