.container {
    margin: 0px;
    padding: 0px;
}

.attributes {
    margin: 0px;
    padding: 0px;
}

.image {
    width: 95%
}