.container {
  display: flex;
  flex-direction: column;
  margin-top: 35px
}

.color-container {
  padding-bottom: 15px;
  align-items: flex-start
}

.color {
  font-size: 1.15em;
  text-align: left;
}

.sizes-container {
  padding-bottom: 15px;
  align-items: flex-start
}

.sizes {
  font-size: 1.15em;
  text-align: left;
}

.quantity-container {
  padding-bottom: 15px;
  align-items: flex-start
}

.quantity {
  text-align: left;
}

.qty-input {
  max-width: 45px;
  max-height: 15px
}

@media screen and (max-width: 730px) {
  .container {
    margin-top: 15px
  }
  .color-container {
    padding-bottom: 5px
  }

  .sizes-container {
    padding-bottom: 5px
  }

  .quantity-container {
    padding-bottom: 5px
  }
}

@media screen and (max-width: 630px) {
  
}