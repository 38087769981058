.container {
    display: flex;
}

.top-fading {
    position: fixed;
    top: 114px;
    z-index: 998;
    margin: 5px;
    margin-top: 0px;
    margin-left: 4%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: top 0.3s;
    /*
    height: 50px;
    width: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 35%);
    */
}

.top-fading-inner {
    display: flex;
    padding: 5px;
    background-color: #ffffff;
    border: solid rgb(230, 230, 230) 1px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.123);
    border-top: none;
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    font-family: 'Caveat', cursive;
    font-size: 1.2em;
}

.shop-sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 995;
    width: 208px;
    transition: transform 0.25s ease-out;
    border-right: solid rgb(209, 209, 209) 1px;
    transition: top 0.3s;
}

.shop-sidebar-after-collapse {
    display: flex;
    position: fixed;
    left: 0;
    width: 200px;
    transform: translateX(-100%);
    transition: transform 0.25s ease-out;
    top: 0px
}

.products {
    margin-left: 208px;
    transition: margin-left 0.25s ease-out;
    width: 100%;
}

.products-after-collapse {
    transition: margin-left 0.25s ease-out;
    
}

@media screen and (max-width: 584px) { /* smallScreen query */
    .shop-sidebar {
        width: 100vw;
        background-color: white;
         
    }

    .shop-sidebar-after-collapse {
        background-color: white;
    }

    .products {
        margin-left: 0px;
    }
}


@media screen and (max-height: 655px) { /* min height for the shop sidebar to be fully extended and not go off screen */
    .shop-sidebar {
        width: 100vw;
        height: 75%;
        background-color: white;
        box-shadow: 0px 300px 0px white
    }

    .shop-sidebar-after-collapse {
        transition: transform 0s ease-out;
        transition: transform 0.25s ease-out;
        background-color: white;
    }
    
    .products {
        display: none;
        margin-left: 0px;
    }
    
}

@media screen and (min-width: 900px) {
    .top-fading {
        margin-left: 33px;
    }
}