.container {
    /*
    background-color: #ffffff;
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    padding: 8px;
    padding-right: 95px;
    border: solid rgb(230, 230, 230) 1px;
    border-top: none;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.123);
    display: flex;
    justify-content: space-between;
    align-items: center
    */
}

.order-select {
    border-radius: 5px;
    font-size: 12px;
    
    border: none;
    width: 100%;
    width: 150px;
    text-transform: capitalize;
    color: #fff;
    background: #292929;
    text-align: left;
    padding: 5px;
    cursor: pointer;
    /* box-shadow: 0px 0px 5px 8px white; */
    background-color: #292929;
}