.container {
    
}

.logo {
    color: #ebfae4;
    font-family: 'Caveat', cursive;
    font-size: 5em;
    -webkit-text-stroke: 1px black;
}

.mountains {
    position: fixed;
    z-index: 1;
    width: 100%;
    bottom: -15%;
    left: 0px;
}

.title {
    position: fixed;
    z-index: 998;
    top: 135px;
    left: 50%;
    transform: translateX(-50%);
}

.down-arrow {
    display: none;
}

.leafs-left {
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 5;
    left: 0px;
}

.leafs-right {
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 5;
    right: 0px;
}

.side-leaves {
    position: fixed;
    z-index: 0;
    bottom: 0px;
    height: 100vh;
    width: 50%;
    filter: blur(8px);
    opacity: 35%;
}

@media screen and (max-width: 584px) {
    .down-arrow {
        display: inline-block;
        margin-top: 50px;
        width: 150px;
    }
}