.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 100%;
    /*
    border-bottom: solid rgb(238, 238, 238) 1px;
    box-shadow: 0px 3px 3px rgb(182, 182, 182);
    */
    background-color: rgba(255, 255, 255, 0.93);
    /*
    background: rgb(253,253,253);
    background: linear-gradient(180deg, rgba(253,253,253,1) 28%, rgba(253,253,253,0.896796218487395) 100%);
    */
}

.logo-container {
    display: flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
}

.logo {
    justify-self: center;
    align-self: center;
    height: 63px;
    width: 175px;
    margin-left: 30px;
    margin-right: 30px;
}

.link {
    text-decoration: none;
    display: flex;
    color: black;
    margin: 19px;
}

.items-right-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.items-left-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    margin-right: 15px;
}

.icon-container {
    cursor: pointer;
    height: 100%
}

.icon {
    width: 19px;
    height: 19px;
    cursor: pointer;
}

.carticon {
    width: 50px;
    height: 32px;
}

.icon:hover {
    filter: drop-shadow(1px 3px #5eda5e)
}

.item-count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12px;
    width: 12px;
    position: absolute;
    z-index: 998;
    margin-left: 30px;
    top: 12px;
    background-color: red;
    border-radius: 50%;
    padding: 2px;
    box-shadow: 0px 0px 0px 3px rgb(253, 253, 253);
    font-family: 'Baloo 2', cursive;
    font-size: 12px;
    color: #ffffff;
    font-weight: 800
}

@media screen and (max-width: 431px) {
    
    .logo {
        width: 80%;
        height: 80%;
        margin: 0px;
    }
    
    .logo-container {
        margin-right: 17.5px;
        margin-left: 20px;
    }

    .icon {
        max-width: 19px;
    }
}