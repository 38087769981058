.container {
    display: flex;
}

.left-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 5vw;
}

.right-container {
    text-align: center;
    right: 0px;
    background-color: #f5f5f5;
    height: 100vh;
    padding: 5vw;
    width: 50%;
}