.container {
  border-bottom: solid gray 1px;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 5vh;
  position: absolute;
  z-index: 999;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
  left: 0px;
  overflow-y: auto;
  width: 100vw;
  height: 59vh;
  animation-duration: 0.35s;
  animation-name: expanding;
  cursor: default;
  box-shadow: 0px 5px 5px -15px rgb(68, 68, 68);
  background-color: rgb(248, 248, 248);
  /*
  background-image: url('../image/ezgif.gif');
  background-position: bottom;
  background-repeat: repeat-x;
  */
}

.expander {
  border: none;
}

@keyframes expanding {
  from {
    height: 0vh
  }
  to {
    height: 59vh;
  }
}

.thrifted-toggle {
  text-align: left;
  font-family: 'Baloo 2', cursive;
}

.thrifted-checked {
  border: none;
  border-radius: 5px;
  background-color: #77ea779c;
  color: white;
  cursor: pointer;
  font-family: inherit;
  margin: 3px;
}

.thrifted-unchecked {
  border: none;
  background: none;
  cursor: pointer;
  font-family: inherit;
}

.thrifted-unchecked:hover {
  background: rgb(230, 230, 230);
  border-radius: 5px;
}

.thrifted-unchecked:active {
  background: rgb(250, 250, 250);
  border-radius: 5px;
}

.link {
  margin: 8px;
  color: rgb(99, 99, 81);
  text-decoration: none;
}

.link-underline {
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 5px;
  color: rgb(99, 99, 81);
  text-decoration: none;
  font-size: 1.19em;
  position: relative;
}

.link-underline::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #77ea77;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link-underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.categories-container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.hr {
    width: 100%;
    margin: 0px;
    margin-bottom: 15px;
    align-self: flex-start;
}

.brand-container {
    display: flex;
    flex-direction: column
}

.brand-scroll {
    max-width: 50vw;
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0px;
    max-height: 200px;
    overflow-y: auto;
    font-size: 0.8em;
}

.brand-scroll::-webkit-scrollbar {
    width: 8px;
}

.brand-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

.brand-scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 5px;
  }

@media screen and (max-width: 500px) {

    .brand-scroll {
        overflow-y: scroll;
        height: 150px;
        min-width: 135px;
        margin-right: 5px;
    }

    .hr {
        max-width: 100px;
    }
}