.container {

}

.sides {
    display: flex;
    width: 100%;
}

.left-container {
    display: flex;
    flex-direction: column;
    margin: 15px;
    gap: 15px;
    width: 50%;
}

.right-container {
    display: flex;
    margin: 15px;
    width: 50%;
}

.separator {
    width: 80%
}

@media screen and (max-width: 500px) {
    .sides {
        flex-direction: column;
        align-items: center;
    }

    .left-container {
        width: 95%;
    }

    .right-container {
        width: 95%;
    }
}