.container {
    display: flex;
    flex-direction: column
}

.container-top {
    display: flex;
    justify-content: center;
    padding: 15px;
}

.alt-images-container {
    padding: 15px;
}

.main-image {
    width: 100%;
}

.right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 35px;
    padding-left: 15px;
    min-width: 190px;
}

.brand-container {
    padding-bottom: 15px;
}

.brand {
    text-decoration: none;
    font-weight: bold;
    color: gray
}

.name {
    font-size: 1.8em
}

.container-bottom {
    padding: 30px;
    padding-right: 15vw;
    padding-left: 15vw;
    padding-bottom: 150px;
}

@media screen and (max-width: 730px) {
    .right-container {
        
    }
}

@media screen and (max-width: 630px) {
    .container-top {
        flex-direction: column
    }

    .main-image {
        order: 1
    }

    .alt-images-container {
        order: 2
    }

    .right-container {
        order: 3;
        padding-left: 0px;
    }
}