.container {
    display: flex;
}

.form {
    text-align: left;
    display: flex;
}

.left-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 5vw;
}

.form-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
}

.input {
    width: 100%;
    height: 35px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 15px;
}

.right-container {
    text-align: center;
    right: 0px;
    background-color: #f5f5f5;
    height: 100vh;
    padding: 5vw;
    width: 50%;
}

@media screen and (max-width: 590px) {
    .container {
        flex-direction: column;
    }

    .left-container {
        width: 100%;
    }

    .right-container {
        width: 100%;
        height: 100%;
    }
}