.container {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  color: black;
  flex-direction: column;
  z-index: 998;
  margin: auto;
  margin-top: 50px;
  width: 150px;
}

.container::-webkit-scrollbar {
  width: 8px;
  margin-right: 8px;
  height: 12px;
}

.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
  
}

.checkbox {
  accent-color: #939393;
}


.thrifted-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin: 0px 5px 0px 5px;
}

.thrifted-toggle {
  text-align: left;
  font-family: 'Baloo 2', cursive
}

.thrifted-checked {
  border: none;
  border-radius: 5px;
  background-color: #77ea77a9;
  cursor: pointer;
  font-family: inherit;
  margin: 3px;
  padding: 3px;
  color: rgb(49, 49, 47);
  color: white;
}

.thrifted-unchecked {
  border: none;
  background: none;
  cursor: pointer;
  font-family: inherit;
  margin: 0px;
  color: rgb(80, 80, 73);
}

.thrifted-unchecked:hover {
  background: rgb(230, 230, 230);
  border-radius: 5px;
}

.thrifted-unchecked:active {
  background: rgb(250, 250, 250);
  border-radius: 5px;
}

.categories-label {
  display: none;
}

.category-container {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 0px 5px 5px white;
  margin: 0px 5px 0px 5px;
}

.brand-container {
  padding: 12px 0px 15px 0px;
  width: 135px;
  margin: 0px 5px 0px 5px;
}

@media screen and (max-width: 584px) {
  .container {
    justify-content: center;
  }

  .shop-sidebar {
    margin-left: 0px;
    width: 300px;
    align-items: center;
  }

  .thrifted-container {
    order: 1;
    margin-top: 5px;
  }

  .categories-label {
    display: block;
  }
  
  .category-container {
    order: 3;
    height: 500px;
    width: 50vw
  }

  .brand-container {
    order: 2;
    margin-top: 15px;
  }
}

@media screen and (max-height: 655px) {
  .shop-sidebar {
    padding: 15px;
    width: 100vw;
    margin: 15px;
    margin-left: 0px;
    padding-left: 0px;
    flex-wrap: wrap;
    align-items: center;
  }

  .thrifted-container {
    order: 1;
    margin-top: 5px;
  }
  
  .category-container {
    order: 3;
    height: 500px;
    width: 50vw
  }

  .brand-container {
    order: 2;
    margin-top: 15px;
  }
}
