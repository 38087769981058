.label {
    position: absolute;
    left: 50%;
    width: 150px;
    margin-left: -75px;
    margin-top: 15px;
    background-color: rgba(233, 207, 174, 0.596);
    border-radius: 15px
}

.right-left-container {
    display: flex;
}

.left-container {
    padding-top: 39px;
    width: 50%
}

.hr {
    display: none;
}

.right-container {
    right: 0px;
    background-color: #f5f5f5;
    padding-top: 39px;
    height: 100vh;
    width: 50%
}

@media screen and (max-width: 584px) {
    .right-left-container {
        flex-direction: column;
        align-items: center;
    }

    .hr {
        display: block;
        width: 80%;
    }
    
    .left-container {
        width: 100%
    }

    .right-container {
        height: 100%;
        width: 80%;
        padding-top: 5px;
        border-radius: 8px
    }
}