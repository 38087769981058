.App {
  text-align: center;
  font-size: 1.15em;
  /* font-family: 'Armata', sans-serif; */
  /* font-family: 'Questrial', sans-serif; */
  /* font-family: 'Baloo 2', cursive; */
  font-family: "Raleway", sans-serif;
  font-family: "Questrial", sans-serif;
  color: rgb(99, 99, 81);
  font-size: 0.95em;
  overscroll-behavior: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.top {
  position: fixed;
  width: inherit;
  top: 0;
  z-index: 999;
}

.top-banner {
  position: fixed;
  height: 12px;
  width: 100%;
  transition: top 0.3s;
}

.top-bar {
  position: fixed;
  height: 63px;
  width: 100%;
  transition: top 0.3s;
}

.navigation {
  position: fixed;
  top: 81px;
  height: 16px;
  width: 100%;
}

.pages {
  margin-top: 114px;
}

.background {
  background-color: #ebfae4
}

.site-footer {
  
}

.btn {
  margin: 5px;
  padding: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-family: "Baloo 2", cursive;
  background-color: #f8f9fa;
  background-color: white;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  color: #3c4043;
  cursor: pointer;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: pre;
}

.btn:hover {
  border-color: #dadce0;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 1px;
  color: #202124;
}

.btn:focus {
  border-color: #464646;
  outline: none;
}

.btn:disabled {
  background-color: gray;
}

.input-wrp {
  position: relative;
}

.input-wrp .input-wrp-text {
  padding-top: 15px;
  padding-left: 8px;
  padding-bottom: 3px;
  width: 100%;
  height: 35px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: none !important;
}

.input-wrp .float-label {
  position: absolute;
  pointer-events: none;
  top: 30px;
  left: 8px;
  transition: 0.2s ease all;
}

.input-wrp input:focus ~ .float-label,
.input-wrp input:not(:focus):valid ~ .float-label {
  top: 23px;
  left: 8px;
  font-size: 12px;
  opacity: 1;
}
