.form {
    padding: 20px;
    text-align: left;
    display: 'flex';
    flex-direction: column;
}

.input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 15px;
}